import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Landing from './Landing';
import Calendar from './Calendar';
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/:id",
    element: <Calendar />,
  },
]);

dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
  weekStart: 1
})

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);