import {useEffect, useState,} from "react";
import { useParams } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import CalendarNewEventModal from "./CalendarNewEventModal";
import CalendarEditEventModal from "./CalendarEditEventModal";

import { db } from "./firebase"
import { doc, getDoc, setDoc } from "firebase/firestore";

import { customAlphabet } from 'nanoid'
const nanoid = customAlphabet('abcdefghkmnopqrstuvxyz', 8)

function Calendar() {
  const [events, setEvents] = useState([]);
  const [selectedDateTime, setSelectedDateTime] = useState();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isCalendarNewEventModalOpen, setIsCalendarNewEventModalOpen] = useState(false);

  const {id} = useParams();
  
  useEffect(() => {
    if (!id) return;

    const fetchData = async () => {
      const docRef = doc(db, "calendar", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setEvents(docSnap.data().events || []);
      }
    };

    fetchData();
  }, [id]);

  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  const saveEvents = async (events) => {
    setEvents(events);
    const docRef = doc(db, "calendar", id);
    await setDoc(docRef, { events: events }, { merge: true });
  };

  const handleCalendarEditEventModalOk = (event) => {
    const _events = events.map((e) => (e.id !== event.id ? e : event));
    saveEvents(_events);
    setSelectedEvent(null);
  };

  const handleCalendarEditEventModalCancel = (deleteEvent) => {
    if (deleteEvent && selectedEvent) {
      const _events = events.filter((e) => e.id !== selectedEvent.id);
      saveEvents(_events);
    }
    setSelectedEvent(null);
  };

  const handleCalendarNewEventModalOk = (
    title,
    start,
    end,
  ) => {
    const _events = [
      ...events,
      {
        title: title,
        start: start,
        end: end,
        id: nanoid(),
      },
    ];
    saveEvents(_events);
    setIsCalendarNewEventModalOpen(false);
  };

  const onEventClick = (event) => {
    const eventId = event.event._def.publicId;
    setSelectedEvent(events.find((event) => event.id === eventId));
  };

  return (
    <>
     {isCalendarNewEventModalOpen && (
        <CalendarNewEventModal
          handleOk={handleCalendarNewEventModalOk}
          handleCancel={() => setIsCalendarNewEventModalOpen(false)}
          selectedDateTime={selectedDateTime}
        />
      )}
      {selectedEvent && (
        <CalendarEditEventModal
        handleOk={handleCalendarEditEventModalOk}
        handleCancel={handleCalendarEditEventModalCancel}
        event={selectedEvent}
        />
      )}
    <div>
      <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
          events={events}
          dayMaxEvents={true}
          initialView="dayGridMonth"
          contentHeight={720}
          firstDay={1}
          displayEventTime={false}
          displayEventEnd={false}
          eventTimeFormat={{
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          }}
          slotLabelFormat={{
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          }}
          eventDisplay="block"
          selectable={true}
          customButtons={{
            new: {
              text: "+",
              click: () => setIsCalendarNewEventModalOpen(true),
            },
          }}
          headerToolbar={{
            left: "timeGridWeek,dayGridMonth today",
            center: "title",
            right: `new prev,next`,
          }}
          eventClick={(event) => onEventClick(event)}
          select={(dateTime) => setSelectedDateTime(dateTime)}
        />
    </div>
    </>
  );
}

export default Calendar;
