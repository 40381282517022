import { Link } from 'react-router-dom';
import { customAlphabet } from 'nanoid'
const nanoid = customAlphabet('abcdefghkmnopqrstuvxyz', 8)
function Landing() {
  const id = nanoid()

  return (
    <div className="landing">
      <h1>Calendar</h1>
      <p>Create both personal and shared calendars, and bookmark them to easily access later.</p>
      <Link to={id}>Create new calendar</Link>
    </div>
  );
}

export default Landing;
