import {useState} from "react";
import { Modal, Input, DatePicker, Button } from "antd";
import dayjs from "dayjs";

const CalendarEditEventModal = ({
  handleCancel,
  handleOk,
  event
}) => {
  const [title, setTitle] = useState(event.title);
  const [startDateTime, setStartDateTime] = useState(dayjs(event.start));
  const [endDateTime, setEndDateTime] = useState(dayjs(event.end));

  return (
    <Modal
    className="no-select"
    title="Edit Event"
    open={true}
    onCancel={() => handleCancel(false)}
    closable={false}
    maskClosable={false}
    okButtonProps={{
      disabled: !(startDateTime && endDateTime),
    }}
    footer={[
      <Button key="cancel" onClick={() => handleCancel(false)}>
        Cancel
      </Button>,
      <Button key="delete" type="danger" onClick={() => handleCancel(true)}>
        Delete
      </Button>,
      <Button
        key="ok"
        type="primary"
        onClick={() =>
          handleOk({
            ...event,
            title: title,
            start: dayjs(startDateTime).format("YYYY-MM-DD HH:mm"),
            end: dayjs(endDateTime).format("YYYY-MM-DD HH:mm"),
          })
        }
      >
        Ok
      </Button>,
    ]}
  >
    <div className="calendar-modal">
      <div className="calendar-modal-item">
        <p>Title</p>
        <Input
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <p>Start</p>
      <DatePicker.RangePicker
        showTime={{ format: "HH:mm" }}
        format="DD.MM.YYYY HH:mm"
        minuteStep={5}
        
        needConfirm={false}
        placeholder={["Start", "End"]}
        className="calendar-modal-item"
        value={[startDateTime, endDateTime]}
        onChange={(date) => {setStartDateTime(date?.[0] ?? ""); setEndDateTime(date?.[1] ?? "")}}
      />
    </div>
  </Modal>
);
};

export default CalendarEditEventModal;  