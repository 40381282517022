import {useState} from "react";
import { Modal, Input, DatePicker } from "antd";
import dayjs from "dayjs";

const CalendarNewEventModal = ({
  handleCancel,
  handleOk,
  selectedDateTime,
}) => {
  const [title, setTitle] = useState("");
  const [startDateTime, setStartDateTime] = useState(
    dayjs(selectedDateTime?.start)
  );
  const [endDateTime, setEndDateTime] = useState(
    dayjs(selectedDateTime?.end)
  );
  console.log(startDateTime);

  return (
    <Modal
      className="no-select"
      title="New Event"
      open={true}
      onCancel={handleCancel}
      onOk={() =>
        handleOk(
          title,
          dayjs(startDateTime).format("YYYY-MM-DD HH:mm"),
          dayjs(endDateTime).format("YYYY-MM-DD HH:mm"),
        )
      }
      closable={false}
      maskClosable={false}
      okButtonProps={{
        disabled: !(startDateTime && endDateTime),
      }}
    >
      <div className="calendar-modal">
        <div className="calendar-modal-item">
          <p>Title</p>
          <Input
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <p>Duration</p>
        <DatePicker.RangePicker
          showTime={{ format: "HH:mm" }}
          format="DD.MM.YYYY HH:mm"
          minuteStep={5}
          needConfirm={false}
          placeholder={["Start", "End"]}
          className="calendar-modal-item"
          value={[startDateTime, endDateTime]}
          onChange={(date) => {setStartDateTime(date?.[0] ?? ""); setEndDateTime(date?.[1] ?? "")}}
        />
      </div>
    </Modal>
  );
};

export default CalendarNewEventModal;